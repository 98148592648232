body.adblock {
  & > .banner {
    display: block !important;
    background: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 0.75em;
    color: white;
    text-align: center;
    font-family: Lato, 'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Sans-Serif;
    line-height: 1.2;
    font-size: 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;

    & > .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 32px;
      height: 32px;
      opacity: 0.33;
      cursor: pointer;
    }

    .close:hover {
      opacity: 1;
    }

    .close::before,
    .close::after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #606060;
    }

    .close::before {
      transform: rotate(45deg);
    }

    .close::after {
      transform: rotate(-45deg);
    }
  }
}
